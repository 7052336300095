<template>
  <div :class="$style.page">
    <div :class="$style.wrapper">
      <div :class="$style.search">
        <Autocomplete
          :search="query.search"
          placeholder="Поиск"
          :valueNames="['name']"
          @querySearch="querySearch"
          @handleFindItems="getTagsList"
          @selectItem="handleUpdateTag($event.id)"
          @input="query.search = $event"
        />
        <el-button
          :class="$style.searchButton"
          type="primary"
          @click="getTagsList"
        >
          Показать
        </el-button>
      </div>

      <el-button type="primary" @click="handleCreateTag">Создать тег</el-button>
    </div>
    <el-table :data="tags" stripe>
      <el-table-column prop="name" label="Название" sortable />
      <el-table-column width="100">
        <template slot-scope="scope">
          <ActionButtons
            editModal
            @delete="deleteTag(scope?.row)"
            @edit="handleUpdateTag(scope?.row?.id)"
          />
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="totalPage > 1"
      background
      layout="prev, pager, next"
      :page-size="query.limit"
      :total="count"
      :current-page.sync="query.page"
      :class="$style.pagination"
      @current-change="getTagsList"
    />
    <el-dialog
      :visible.sync="isModalOpen"
      show-close
      :class="$style.dialog"
      :title="
        currentModalType === $options.MODAL_TYPES.CREATE
          ? 'Создание тега'
          : 'Редактирование тега'
      "
      :before-close="handleCloseForm"
    >
      <el-form
        ref="form"
        :model="form"
        :class="$style.form"
        label-position="right"
        :rules="rules"
      >
        <el-form-item label="Название" prop="name">
          <el-input v-model="form.name" :class="$style.input" />
        </el-form-item>
      </el-form>
      <el-button
        type="primary"
        :class="$style.createButton"
        @click="submitForm"
        >{{
          currentModalType === $options.MODAL_TYPES.CREATE
            ? 'Создать'
            : 'Редактировать'
        }}</el-button
      >
    </el-dialog>
  </div>
</template>
<script>
import delivery from '@/delivery'
import notifications from '@/mixins/notifications'

import ActionButtons from '@/components/atoms/ActionsButtons.vue'
import Autocomplete from '@/components/atoms/Autocomplete.vue'

import { MODAL_TYPES, SORTING_TYPES } from '@/constants/common'

export default {
  components: { ActionButtons, Autocomplete },
  MODAL_TYPES,
  SORTING_TYPES,
  mixins: [notifications],
  data() {
    return {
      isModalOpen: false,
      currentModalType: MODAL_TYPES.CREATE,
      tags: [],
      count: 0,
      currentTagId: '',
      form: {
        name: '',
      },
      query: {
        page: 1,
        limit: 20,
        orderBy: 'created_at',
        orderDir: SORTING_TYPES.DESC,
        search: '',
      },
      rules: {
        name: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  computed: {
    totalPage() {
      return Math.ceil(this.count / this.query.limit)
    },
  },
  async created() {
    await this.getTagsList()
  },
  methods: {
    async getTagsList() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.AddeventServiceCore.TagsActions.getList(this.query)

      loading.close()

      if (error) return

      this.tags = value?.data
      this.count = value?.meta?.count
    },
    async getTag(id) {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.AddeventServiceCore.TagsActions.getOne(id)

      loading.close()

      if (error) {
        this.showNotification('Ошибка получения тега', 'error')
      }

      this.form = value
    },
    async createTag() {
      const loading = this.$loading({
        lock: true,
      })

      const { error } = await delivery.AddeventServiceCore.TagsActions.create({
        ...this.form,
      })

      loading.close()

      if (error) {
        this.showNotification('Ошибка создания тега', 'error')
        return
      }

      this.showNotification('Тег успешно создан', 'success')
    },
    async updateTag() {
      const loading = this.$loading({
        lock: true,
      })

      const { error } = await delivery.AddeventServiceCore.TagsActions.update(
        { ...this.form },
        this.currentTagId,
      )

      loading.close()

      if (error) {
        this.showNotification('Ошибка обновления тега', 'error')
        return
      }

      this.showNotification('Тег успешно обновлен', 'success')
    },
    async deleteTag(tag) {
      const isConfirm = confirm(`Вы точно хотите удалить тег: ${tag.name}`)

      if (!isConfirm) return

      const loading = this.$loading({
        lock: true,
      })

      const { error } = await delivery.AddeventServiceCore.TagsActions.delete(
        tag?.id,
      )

      loading.close()

      if (error) {
        this.showNotification('Ошибка удаления тега', 'error')
        return
      }

      this.showNotification('Тег успешно удален', 'success')
      await this.getTagsList()
    },
    handleCreateTag() {
      this.isModalOpen = true
      this.currentModalType = MODAL_TYPES.CREATE
    },
    async handleUpdateTag(id) {
      this.currentModalType = MODAL_TYPES.UPDATE
      this.isModalOpen = true
      this.currentTagId = id

      await this.getTag(id)
    },
    resetForm() {
      this.form.name = ''
    },
    handleCloseForm() {
      this.isModalOpen = false
      this.resetForm()
    },
    async submitForm() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return

        this.currentModalType === MODAL_TYPES.CREATE
          ? await this.createTag()
          : await this.updateTag()

        this.resetForm()
        this.isModalOpen = false
        await this.getTagsList()
      })
    },
    async querySearch({ queryString, setSearchItems }) {
      const { value, error } =
        await delivery.AddeventServiceCore.TagsActions.getList({
          limit: 100,
          page: this.query.page,
          search: queryString,
        })

      if (error) return

      setSearchItems(value.data)
    },
  },
}
</script>
<style lang="scss" module>
.page {
  padding: 1rem;

  .wrapper {
    @include stickyWrapper;
    justify-content: space-between;
  }

  .pagination {
    @include pagination;
  }

  .dialog {
    .createButton {
      margin-top: 1rem;
    }
  }
}
</style>
